import React, { useState, useCallback, useEffect, useContext } from 'react'
import { Button, TouchableOpacity, Text, View, SafeAreaView, FlatList, Dimensions, Image, StatusBar, Platform, TextInput } from 'react-native'
import { themeColor, fontStyles, server_var } from '../config/servervar';
import { store } from './store'
import moment from 'moment';
import SegmentedControlTab from './SegmentedControlTab';
import { numberWithCommas, getTotalPriceText } from './common1'
import { DELIVER_LIST, ORDER_STATUS } from './common1';
import { CustomIcon, CustomVideo, CustomDatePicker5 } from '../component/custom';
import EventEmitter from "./EventEmitter";
import { useDebounce } from "./common1";
import { Dialog } from '../sharewebapp/pharmanotepart';

export function ManageOrderPart({ navigation, route }) {
  const forAdmin = route?.params?.forAdmin;
  const forceTabIndex = route?.params?.forceTabIndex;
  const { globalState } = useContext(store);
  const [tabIndex, setTabIndex] = useState((route?.params?.forceTabIndex) ?? 0);
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [queryText, setQueryText] = useState('');
  const [dialog, setDialog] = useState({ visible: false });
  const [searchData, setSearchData] = useState({ statusId: null, status: null, dateFrom: null, dateTo: null });
  const [voidRequest, setVoidRequest] = useState(false);

  const onManageOrderPress = (pressParam) => {
    navigation.navigate('ViewOrder', pressParam);
  }

  const onPharmaNotePress = (pressParam) => {
    navigation.navigate('PharmaNote', pressParam)
  }

  useEffect(() => {
    if (forceTabIndex > 0) {
      setTabIndex(forceTabIndex)
    }
  }, [forceTabIndex])


  const QUERY_LIST = [
    { id: 1, title: 'รอ\nชำระเงิน', query: { orderStatus: 'in_basket' } },
    { id: 2, title: 'ที่ต้อง\nจัดส่ง', query: { orderStatus: { $in: ['customer_paid', 'partner_reject', 'grab_reject'] } } },
    {
      id: 3, title: 'ระหว่าง\nนำส่ง', query: {
        orderStatus: {
          $in: [
            'exta_start_deliver', 'partner_start_deliver', 'self_pickup_start',
            '7deli_start_deliver', 'speedd_start_deliver', 'post_start_deliver',
            'grab_start_deliver'
          ]
        }
      }
    },
    {
      id: 4, title: 'จัดส่ง\nสำเร็จ', query: {
        orderStatus: {
          $in: [
            'partner_complete_deliver', '7deli_complete_deliver', 'self_pickup_complete', 'speedd_complete_deliver',
            'post_complete_deliver', 'grab_complete_deliver'
          ]
        }
      }
    },
    {
      id: 5, title: 'รับสินค้า\nสำเร็จ', query: {
        orderStatus: {
          $in: ['customer_got_deliver', 'exta_complete_deliver']
        }
      }
    },
    { id: 6, title: 'ไม่รับสินค้า\nตามกำหนด', query: { orderStatus: 'selfpickup_not_pickup' } },
    { id: 7, title: 'ยกเลิก', query: { orderStatus: { $in: ['cancel', 'cancel_by_system', 'cancel_by_admin', 'selfpickup_not_pickup'] } } },
  ]

  useEffect(() => {
    EventEmitter.addListener('ManageOrder:refresh', handleRefresh);
    return () => {
      EventEmitter.removeListener('ManageOrder:refresh', handleRefresh);
    };
  }, [tabIndex, globalState?.user])

  const handleRefresh = () => {
    loadData(0);
  }

  useEffect(() => {
    globalState.client.service('notitemplogs').on('created', onNotiListener);
    return () => {
      globalState.client.service('notitemplogs').removeListener('created', onNotiListener);
    };
  }, [tabIndex, globalState?.user]);

  useEffect(() => {
    loadData(0);
  }, [tabIndex, globalState?.user])

  useEffect(() => {
    loadData(0);
  }, [queryText]);

  useEffect(() => {
    if (forAdmin) {
      loadDataForAdmin(0);
    }
  }, [searchData?.statusId, searchData?.dateFrom, searchData?.dateTo])

  const onNotiListener = async (payload) => {
    if (payload.toUserId == globalState.user.id && payload.type == 'RefreshOrder') {
      loadData(0)
    }
  }

  const loadData = async (pSkip) => {
    if (forAdmin) {
      checkOrderVoidRequest();
      loadDataForAdmin(pSkip);
    } else {
      loadDataForUser(pSkip);
    }
  }

  const checkOrderVoidRequest = async () => {
    let res;
    try {
      res = await globalState.client.service('ordermains').find({ query: { orderVoidStatus: { $in: ['void_request', 'void_process'] }, $select: ['id', 'orderVoidStatus'] } });
    } catch { }
    //console.log(themeColor.FgYellow, 'orderVoidStatus ordermains : ' + JSON.stringify(res), themeColor.FgReset);
    if (res?.total > 0) {
      setVoidRequest(true);
    } else {
      setVoidRequest(false);
    }
  }

  const loadDataForAdmin = async (pSkip) => {
    if (!loading) {
      setLoading(true);

      let query = { $skip: pSkip ?? skip, $sort: { id: -1 } }
      let criteriaDateFrom;
      if (searchData?.dateFrom) {
        criteriaDateFrom = moment(searchData?.dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else {
        criteriaDateFrom = moment().format('YYYY-MM-DD');
      }
      let criteriaDateTo;
      if (searchData?.dateTo) {
        criteriaDateTo = moment(searchData?.dateTo, 'DD/MM/YYYY').add(1, 'days').format('YYYY-MM-DD');
      } else {
        criteriaDateTo = moment().add(1, 'days').format('YYYY-MM-DD');
      }
      query = {
        ...query,
        $and: [
          { createdAt: { $gte: criteriaDateFrom } },
          { createdAt: { $lte: criteriaDateTo } }
        ],
      };

      if (queryText) {
        query = {
          ...query,
          $or: [
            { id: { $like: `%${queryText}%` } },
            { '$user.displayName$': { $like: `%${queryText}%` } },
            { '$shop.shopIdBranch$': { $like: `%${queryText}%` } },
            { '$shop.shopThName$': { $like: `%${queryText}%` } }
          ],
        }
      }
      if (searchData?.statusId) {
        query = {
          ...query,
          ...QUERY_LIST[searchData?.statusId - 1].query
        }
      }
      let res2;
      try {
        res2 = await globalState.client.service('ordermains').find({ query });
      } catch { }

      if (res2) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res2.data);
        } else {
          setDataList(prev => [...prev, ...res2.data]);
        }
        setSkip((res2.skip + res2.limit));
      }

      setLoading(false);
    }
  }

  const loadDataForUser = async (pSkip) => {
    if (!loading) {
      setLoading(true);
      let res1, res2;
      try {
        res1 = await globalState.client.service('shopusers').find({
          query: { userId: globalState.user.id, $limit: 400 }
        });
        res2 = await globalState.client.service('ordermains').find({
          query: {
            ...QUERY_LIST[tabIndex].query,
            shopId: { $in: res1?.data?.map(item => item.shopId) },
            $skip: pSkip ?? skip,
            $sort: { id: -1 }
          }
        });
        console.log('query123:', JSON.stringify({
          query: {
            ...QUERY_LIST[tabIndex].query,
            shopId: { $in: res1?.data?.map(item => item.shopId) },
            $skip: pSkip ?? skip,
            $sort: { id: -1 }
          }
        }, '', 2));
      } catch { }
      if (res2) {
        if ((pSkip ?? skip) === 0) {
          setDataList(res2.data);
        } else {
          setDataList(prev => [...prev, ...res2.data]);
        }
        setSkip((res2.skip + res2.limit));
      }
      setLoading(false);
    }
  }

  const onBackDialog = async (backItem) => {
    setSearchData(prev => ({ ...prev, statusId: backItem?.id, status: backItem?.title?.replace(/\n/g, '') }));
    setDialog(prev => ({ ...prev, visible: false }));
  }

  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;
  const dialogProp = { dialog, onBackDialog, setDialog, dialogWidth, QUERY_LIST };
  const dialogContent = [
    {
      type: 'statuslist',
      title: 'สถานะ',
      com: <StatusList {...dialogProp} />,
    },
  ];

  return (
    <View style={{ flex: 1, backgroundColor: 'white', margin: 10, borderRadius: 10 }}>
      <SafeAreaView style={{ flex: 1 }}>
        {!forAdmin &&
          <SegmentedControlTab
            values={QUERY_LIST.map(item => item.title)}
            textNumberOfLines={2}
            selectedIndex={tabIndex}
            tabTextStyle={{ textAlign: 'center', color: themeColor.color1 }}
            onTabPress={(index) => setTabIndex(index)}
            tabsContainerStyle={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}
            activeTabStyle={{ backgroundColor: themeColor.color1 }}
            tabStyle={{ borderColor: themeColor.color1 }}
          />
        }
        { voidRequest &&
        <TouchableOpacity onPress={() => {
          navigation.navigate('ManageOrderVoid');
        }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginHorizontal: 20, marginTop: 10 }}>
            <View style={{ flex: 0.6 }}></View>
            <View style={{ flex: 0.4 }}>
              <View style={{
                  height: 40, backgroundColor: themeColor.color6,
                  borderRadius: 10, justifyContent: 'center', alignItems: 'center'
                }}>
                  <Text style={{ color: 'white' }}>รายการขอยกเลิก</Text>
              </View>              
            </View>
          </View>
        </TouchableOpacity>
        }
        {forAdmin && <SearchBox queryText={queryText} setQueryText={setQueryText} dialog={dialog} setDialog={setDialog} searchData={searchData} setSearchData={setSearchData} />}
        <FlatList
          keyExtractor={(item) => item.id.toString()}
          data={dataList}
          style={[{ marginTop: 10 }, Platform.OS == 'web' ? { height: '85vh' } : null]}
          onEndReached={(xxx) => loadData()}
          onEndReachedThreshold={0.5}
          onRefresh={() => loadData(0)}
          refreshing={(loading && skip == 0)}
          renderItem={({ item }) => (
            <Item item={item} onManageOrderPress={onManageOrderPress} onPharmaNotePress={onPharmaNotePress} />
          )}
        />
        <Dialog dialog={dialog} setDialog={setDialog} onBackDialog={onBackDialog} dialogWidth={dialogWidth} dialogContent={dialogContent} />
      </SafeAreaView>
    </View>
  )
}

function SearchBox({ queryText, setQueryText, dialog, setDialog, searchData, setSearchData }) {
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 1000);
  const dialogWidth = (Platform.OS == 'web') ? Dimensions.get('window').width / 4 : Dimensions.get('window').width * 0.8;

  useEffect(() => {
    setQueryText(searchText)
  }, [debouncedSearchTerm])

  return (
    <View style={{ marginHorizontal: 20, marginVertical: 10 }}> 
      <TextInput
        value={searchText}
        onChangeText={text => setSearchText(text)}
        style={{ borderWidth: 1, borderColor: 'lightgray', borderRadius: 5, height: 35, paddingHorizontal: 5 }} placeholder="ค้นหาจาก หมายเลขคำสั่งซื้อ / ชื่อ-นามสกุล / สาขาที่ให้บริการ" />

      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ตั้งแต่วันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateFrom}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateFrom: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>ถึงวันที่</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <CustomDatePicker5
            value={searchData?.dateTo}
            onChange={(selectedDate) => {
              setSearchData(prev => ({ ...prev, dateTo: moment(selectedDate).format('DD/MM/YYYY') }))
            }}
            dialogWidth={dialogWidth}
          />
        </View>
      </View>

      <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
        <View style={{ flex: 0.3 }}>
          <Text style={{ fontSize: 14 }}>สถานะ</Text>
        </View>
        <View style={{ flex: 0.7 }}>
          <TouchableOpacity onPress={() => setDialog(prev => ({ ...prev, visible: true, type: 'statuslist' }))}>
            <View style={{
              flexDirection: 'row', borderColor: 'lightgray', borderRadius: 5, borderWidth: 1, paddingHorizontal: 5,
              height: 30, alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white'
            }}>
              <Text style={{ fontSize: 14, color: 'gray' }}>{searchData?.status ?? 'ทั้งหมด'}</Text>
              <CustomIcon name="chevron-down" size={20} color="gray" />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

function Item({ item, onManageOrderPress, onPharmaNotePress }) {
  return (
    <TouchableOpacity onPress={() => onManageOrderPress({ ordermainId: item.id })}>
      <View style={{
        paddingVertical: 10, borderBottomWidth: 1,
        borderColor: 'lightgray', paddingBottom: 15, marginHorizontal: 20,
      }}>
        {item?.orderStatus == 'customer_paid' && (moment(item?.paymentDate).add(30, 'minutes') < moment()) &&
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <View style={{
                height: 25, backgroundColor: themeColor.color6,
                borderRadius: 5, justifyContent: 'center', alignItems: 'center', marginVertical: 10
              }}>
                <Text style={{ color: 'white' }}>เกินกำหนด SLA</Text>
              </View>
            </View>
            <View style={{ flex: 0.6 }}></View>
          </View>
        }
        {['partner_reject', 'grab_reject'].includes(item?.orderStatus) &&
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <View style={{
                height: 25, backgroundColor: themeColor.color6,
                borderRadius: 5, justifyContent: 'center', alignItems: 'center', marginVertical: 10
              }}>
                <Text style={{ color: 'white' }}>ไรเดอร์ปฏิเสธงาน</Text>
              </View>
            </View>
            <View style={{ flex: 0.6 }}></View>
          </View>
        }
        {[
          { id: 1, title: 'หมายเลขคำสั่งซื้อ', value: item?.id, textStyle: { color: themeColor.color1 } },
          { id: 2, title: '7-Deli Order No.', value: item?.deliOrderNumber ?? '', textStyle: {} },
          { id: 3, title: 'ผู้รับบริการ', value: item?.user?.displayName ?? '', textStyle: {} },
          { id: 4, title: 'สาขาที่ให้บริการ', value: item?.shop?.shopThName, textStyle: {} },
          { id: 5, title: 'การจัดส่ง', value: DELIVER_LIST.find(item2 => item2.value == item?.deliverType)?.title, textStyle: {} },
          { id: 6, title: 'สถานะ', value: ORDER_STATUS.find(item2 => item2.value == item?.orderStatus)?.title2 ?? ORDER_STATUS.find(item2 => item2.value == item?.orderStatus)?.title + (item?.orderStatus == 'customer_paid' ? ( item?.paymentPartialStatus == 'appstaff_deduct_money' ? ' (ตัดเงินสำเร็จ)' : '') : ''), textStyle: {} },
          { id: 7, title: 'ยอดชำระ', value: getTotalPriceText(item), textStyle: {} },
          { id: 8, title: 'วันและเวลาที่สั่งซื้อ', value: moment(item.createdAt).format('DD/MM/YYYY HH:mm'), textStyle: { color: 'gray' } },
        ].map(item3 => (
          <View key={item3.id.toString()} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 0.4 }}>
              <Text style={item3?.textStyle}>{item3?.title}</Text>
            </View>
            <View style={{ flex: 0.1 }}>
              <Text style={item3?.textStyle}>:</Text>
            </View>
            <View style={{ flex: 0.5 }}>
              <Text style={item3?.textStyle}>{item3?.value}</Text>
            </View>
          </View>
        ))}

        {item?.oldqueline?.pharmanote?.status == null && (
          <TouchableOpacity
            onPress={() => {
              onPharmaNotePress({
                queId: item?.oldqueline?.oldId,
                userId: item?.oldqueline?.userId,
                pharmaId: item?.oldqueline?.acceptByUserId,
                shopId: item?.oldqueline?.shopId2,
              })
            }}
            style={{ marginTop: 10 }}
          >
            <View style={{ flexDirection: 'row', backgroundColor: themeColor.color6, height: 35, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
              <Image
                source={{ uri: `${server_var.server_api}/images/exclamation1.png` }}
                style={{ width: 15, height: 15 }} resizeMode="contain"
              />
              <Text style={{ color: 'white', fontSize: 12, marginLeft: 5 }}>บันทึกประวัติการให้คำปรึกษาและปิดห้องสนทนา</Text>
            </View>
          </TouchableOpacity>
        )}
      </View>
    </TouchableOpacity>
  )
}

function StatusList({ dialog, setDialog, onBackDialog, dialogWidth, QUERY_LIST }) {
  let statusList = [{ id: 0, title: 'ทั้งหมด' }, ...QUERY_LIST];
  return (
    <View style={{ paddingHorizontal: 5, alignItems: 'center', marginTop: 20 }}>
      {statusList?.map(item => (
        <TouchableOpacity key={item.id.toString()} onPress={() => {
          onBackDialog({ ...item, type: 'statuslist' })
          setDialog(prev => ({ ...prev, visible: false }));
        }}>
          <View style={{
            width: dialogWidth - 30, backgroundColor: themeColor.color1, height: 30,
            justifyContent: 'center',
            paddingHorizontal: 10,
            marginVertical: 10, borderRadius: 5
          }}>
            <Text style={{ color: 'white' }}>{item?.title?.replace(/\n/g, '')}</Text>
          </View>
        </TouchableOpacity>
      ))}
    </View>
  )
}
