export var server_var = {
  status: 'Dev',
  // server_api: 'http://testapi2.local:3030',
  // server_api: 'http://localhost:3030',
  server_api: 'https://api01.allpharmasee.com',
  // apiBackEnd: 'self', //7deli
  apiBackEnd: '7deli', //7deli
  couponMode: false,
  eTaxMode: true,
  // server_api: 'https://extatestapi2.orbitxstudio.com',
  version: '2.4.2',
  builtno: '1',
};

export const themeColor = {

  color0: '#f0f2f5',
  // color1: "#7691e6",
  color1: "#1C57A8",
  color2: "#3D4C7C",
  // color3: "#89cff0",

  // color2: "#376a95",
  color3: '#d8eefb',
  color4: '#E0F6F0',
  color5: '#FDEFD8',
  color6: '#EA6762', //dark red
  color7: '#1C57A8', //new blue
  color8: '#F2F2F2', //very pale gray ,, default screen
  color9: '#76C69C', //pale green
  color10: '#A1CD44', //green
  color11: '#efefef', //light gre
  color12: '#d3d3d3', //light grey 2
};

export const fontStyles = {
  body1: {
    fontSize: 14,
    // fontFamily: defFontFamily,
    lineHeight: 20,
  },
}

export const DELIVER_LIST = [
  { value: 'deliver_now', title: 'ส่งทันที' },
  { value: 'deliver_nextday', title: 'จัดส่งวันถัดไป' }
]

export const PAYMENT_LIST = [
  { value: 'prompt_pay', title: 'Prompt Pay' },
  { value: 'credit_card', title: 'บัตรเครดิต/เดบิต' },
  { value: 'true_money_wallet', title: 'ทรูมันนี่' },
]

///

// export const UNKNOWN_USER_PICT = 'http://192.168.68.253:3030/images/unknown-user-pic.png';

